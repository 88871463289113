<template>
	<div class="row">
		<div class="form-group col-12">
			<label for="newPassword">{{ $route.path != '/profil' ? $t('Heslo') : $t('Nové heslo') }} *</label>
			<input v-model="userInfo.password" type="password" class="form-control" :placeholder="$route.path != '/profil' ? $t('Zadajte heslo') : $t('Zadajte nové heslo')" autocomplete="new-password">
		</div>

		<div v-if="showRepeat" class="form-group col-12">
			<label for="passwordConfirmation">{{ $t('Potvrdenie hesla') }} *</label>
			<input v-model="userInfo.password_confirmation" type="password" class="form-control" :placeholder="$route.path != '/profil' ? $t('Potvrďte heslo') : $t('Potvrďte nové heslo')" autocomplete="new-password">
		</div>
	</div>
</template>

<script>
export default {
	props: { 
		showRepeat: { required: false, default: false },
		userInfo: { required: true }
	}
}
</script>
